import { useRouter } from 'next/router'
import React, { useCallback, useEffect } from 'react'

type FourZeroFourProps = {}

const FourZeroFour = (_: FourZeroFourProps) => {
  const { push } = useRouter()

  const toIndex = useCallback(() => push('/reports'), [push])

  useEffect(() => {
    toIndex()
  }, [toIndex])

  return <div />
}

export default FourZeroFour
